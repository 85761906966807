<template>
  <div class="warp">
    <div class="top-warp">
      <div class="tip-name" style="width: 100%">{{ netName }}</div>
    </div>
    <div class="top-warp">
      <div class="tip-name">货架：{{ vmcCount }}个</div>
      <div class="select-time">
        <van-cell :value="`${startDate} - ${endDate}`"/>
      </div>
    </div>
    <div class="data-info">
      <div class="first-row">
        <div class="val">{{ totalOrders }}</div>
        <div>订单数</div>
      </div>
      <div class="first-row">
        <div class="val">{{ sales }}</div>
        <div>销售额</div>
      </div>
      <div class="first-row">
        <div class="val">{{ wechatPay }}</div>
        <div>微信支付</div>
      </div>
      <div class="first-row">
        <div class="val">{{ balancePay }}</div>
        <div>余额支付</div>
      </div>
    </div>
    <div v-for="item in dataList" :key="item.id" >
      <div v-if="item.status != 6" class="order-info">
        <div class="name">{{ item.address }}</div>
        <div class="time">{{ item.createTime }} 下单</div>
        <div class="infos">
          <span>{{ item.payType == 1 ? '微信支付' : '余额支付' }}</span>
          <span>{{ item.idx }}格口号</span>
          <span>{{ siteOrderStatus[item.status] }}</span>
        </div>
        <div class="name">网点：{{ item.netName }}</div>
        <van-card
            v-for="goodsInfo in item.orderItems" :key="goodsInfo.orderItemId"
            style="background: #f8f7f7"
            :num="goodsInfo.prodCount"
            :price="goodsInfo.price"
            :title="goodsInfo.prodName"
            :thumb="goodsInfo.pic"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { siteOrderStatus } from '../../util/orderStatus.js'
import { orderList } from '../../assets/api/order.js'
export default {
  data() {
    return {
      netId: this.$route.query.netId,
      netName: this.$route.query.netName,
      vmcCount: this.$route.query.vmcCount,
      startDate: this.$route.query.startDate,
      endDate: this.$route.query.endDate,
      totalOrders: this.$route.query.totalOrders,
      sales: this.$route.query.sales,
      wechatPay: this.$route.query.wechatPay,
      balancePay: this.$route.query.balancePay,
      dataList: [],
      siteOrderStatus: siteOrderStatus
    }
  },
  mounted() {
    this.initData()
  },
  beforeRouteLeave(to, from, next){
    if (from.name == 'siteInfos') {
      to.query = from.query
      to.path = `${to.path}?startDate=${from.query.startDate}&endDate=${from.query.endDate}`
      to.fullPath = `${to.fullPath}?startDate=${from.query.startDate}&endDate=${from.query.endDate}`
    }
    next()
  },
  methods: {
    initData() {
      const params = {
        netId: this.netId,
        orderySource: 2,
        createTime: this.startDate +','+ this.endDate,
        current: 1,
        size: 1000,
      }
      orderList(params).then( res => {
        if (res.data.code == 200) {
          this.dataList = res.data.data.records
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-card__thumb) {
  width: 60px;
  height: 60px;
}
::v-deep(.van-card__content) {
  height: 60px;
  min-height: 60px;
}
.warp {
  padding: 15px 10px;
  .top-warp {
    width: 100%;
    display: flex;
    height: 44px;
    line-height: 44px;

    .tip-name {
      width: 100px;
      font-weight: 600;
      font-size: 16px;
      margin-right: 12px;
    }

    .select-time {
      font-weight: 600;
      font-size: 16px;
      width: calc(100% - 112px);
    }
  }
  .data-info {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .first-row {
      height: 60px;
      width: calc(25% - 5px);
      text-align: center;
      border-radius: 8px;
      border: 1px solid #999;

      .val {
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
      }
    }
  }

  .order-info {
    width: calc(100% - 20px);
    color: #666;
    font-size: 12px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    background: #f7f8fabd;

    .name {
      font-size: 16px;
      line-height: 18px;
    }
    .time, .infos {
      margin: 4px 0;
      line-height: 18px;
    }
    .infos {
      display: flex;
      span {
        margin-right: 10px;
        border: 1px solid #999999;
        border-radius: 6px;
        padding: 0 6px;
      }
    }
    .van-btn {
      border: none;
      height: 24px;
      line-height: 24px;
      margin-top: 10px;
      margin-right: 20px;
      border-radius: 5px;
    }
  }
}
</style>
